 /* src/Gallery.css */
.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .gallery-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    border-radius: 10px;
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
  }
  
  .lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .lightbox-content {
    position: relative;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    animation: zoomIn 0.3s ease-in-out;
  }
  
  .lightbox-image {
    max-width: 100%;
    max-height: 80vh;
    border-radius: 8px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes zoomIn {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
  